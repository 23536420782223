.radio-container {
  display: block;
  position: relative;
  //   padding-left: 35px;
  //   margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  @apply inline-block h-[16px] w-[16px] bg-blue4-payment rounded-md relative;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   background-color: #eee;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.radio-container input:checked ~ .checkmark {
  background-color: #49c834;
}

.radio-container input:checked ~ .checkmark-red {
  // background-color: #49C834;
  @apply bg-red-500;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.radio-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.radio-container .checkmark:after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(36deg);
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
}
